@use 'src/css/breakpoint.scss' as *;

.indexSection {
  position: relative;
  margin: 130px auto;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
  }

  @include maxMediaWidth(sm) {
    margin: 50px auto;
  }

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px 0;

    @include maxMediaWidth(lg) {
      padding: 30px 55px;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 15px;
    }

    .container {
      @include maxMediaWidth(lg) {
        padding: 0;
      }
    }
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: center;

  @include maxMediaWidth(lg) {
    grid-template-columns: unset;
    grid-template-rows: auto auto;
    grid-gap: 38px;
  }

  @include maxMediaWidth(sm) {
    grid-template-columns: unset;
    grid-template-rows: auto auto;
    grid-gap: 25px;
  }
}

.title {
  padding-bottom: 15px;

  @include maxMediaWidth(lg) {
    padding-bottom: 10px;
  }

  @include maxMediaWidth(sm) {
    padding-bottom: 25px;
  }
}

.text {
  p {
    font: var(--font-h8);
    color: var(--secondary-text-color);
    margin: 0;

    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }

  @include maxMediaWidth(lg) {
    p {
      font: var(--font-h9-sm);
    }
  }
}

.exchangePairs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 22px 30px;
  height: fit-content;

  @include maxMediaWidth(lg) {
    grid-template-columns: repeat(5, 1fr);
    gap: 16px 18px;
  }

  @include maxMediaWidth(sm) {
    grid-template-columns: 1fr 1fr;
    gap: 15px 16px;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 13px 13px 11px;
  border-radius: 6px;
  background: var(--primary-button-bg-dark-color);
  font: var(--font-h7);
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-button-dark-color);
  cursor: pointer;

  &:hover {
    color: var(--primary-button-hover-dark-color);
    background: var(--primary-button-bg-hover-dark-color);
    text-decoration: none;
  }

  @include maxMediaWidth(lg) {
    font: var(--font-h10-md);
    padding: 9px 13px 7px;
  }
}
